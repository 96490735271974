/* General Body Style */

body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  background-color: #262626;
  font-family: 'Arial', sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
}

/* Footer Note Animation */

#footerNoteSpan {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0%, 49% {
    color: var(--highlight-blue)
  }
  60%, 99% {
    color: transparent;
  }
  100% {
    color: var(--highlight-blue)
  }
}

/* Page Not Found Styles */

.not-found {
  text-align: center;
  padding: 60px;
  border: 1px solid var(--gray-1);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  height: auto;
  max-width: 900px;
  margin: 10vh auto;
  background: var(--deep-dark);
  position: relative;
  animation: fadeIn 1s ease-out;
}

#game-container {
  margin: 30px 0;
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
}

#gameCanvas {
  background-color: #fafafa;
  border: 2px solid #ddd;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

.not-found h1 {
  font-size: 4rem;
  margin-bottom: 20px;
  color: #ea3a1b; /* Bright color for attention */
}

.not-found p {
  font-size: 1.4rem;
  margin: 15px 0;
  color: #ddd;
  line-height: 1.6;
}

.not-found h2 {
  font-size: 2.2rem;
  margin-top: 20px;
  color: var(--highlight-blue);
  font-weight: bold;
}

/* Background for the Not Found page with a subtle animation */
.not-found-img {
  width: 100px;
  height: 100px;
  margin-top: 30px;
  animation: bounce 1.5s infinite;
}

/* Fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Bounce effect for image */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .not-found {
    padding: 40px;
    margin: 5vh auto;
    max-width: 90%;
  }

  .not-found h1 {
    font-size: 3.5rem;
  }

  .not-found p {
    font-size: 1.3rem;
  }

  .not-found h2 {
    font-size: 2rem;
  }

  .not-found-img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 480px) {
  .not-found h1 {
    font-size: 3rem;
  }

  .not-found p {
    font-size: 1.2rem;
  }

  .not-found h2 {
    font-size: 1.8rem;
  }

  .not-found-img {
    width: 70px;
    height: 70px;
  }
}

/* Page Not Found T-Rex Dino Game Styles */

.instructions {
  background-color: var(--gray-1);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: var(--deep-dark);
}

.instructions h3 {
  margin-top: 0;
}

.instructions ul {
  list-style-type: none;
  padding-left: 0;
}

.instructions li {
  margin-bottom: 10px;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #45a049;
}
